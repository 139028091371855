import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import * as style from '../components/css/impressum.module.css'
import Container from '../components/container'
import SEO from '../components/seo'

const impressum = () => {
    return (
        <div className={style.main}>
            <SEO title="Impressum" description="Impressum and privacy policy website english" />
            {/* <Header captionText="Impressum" /> */}
            <Header />
            <Container>
                <h2>Impressum</h2>
                <p>Marco Deubel</p>
                <div>
                    Käthe-Kollwitz-Straße 29
                </div>
                <div>
                    63110 Rodgau
                </div>
                <p>
                    mail [at] universaltimecomplication [dot] de
                </p>
                <div>

                </div>
                <h2>Privacy Policy Website</h2>
                <p>
                    <div>Website views will be logged by hosting provider.</div>
                    <div>The maximum storage time is 6 months.</div>
                    <div>IPs will be anonymized after 7 days.</div>
                    <div>Beyond that, no data is collected.</div>
                </p>
                </Container>
            <Footer />
        </div>
    )
}

export default impressum
